import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../icon/icon';
import Button from '../button/button';
import routesConfig from '../../routesConfig';
import './navigationControl.scss';

const NavigationControl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState({
    isPrevDisabled: true,
    isNextDisabled: false,
    currentIndex: 0,
    totalRoutes: 0,
    isLastPageReached: false
  });

  useEffect(() => {
    updateButtonStates();
  }, [location.pathname]);

  useEffect(() => {
    if (navigationState.isLastPageReached) {
      const timer = setTimeout(() => {
        setNavigationState(prev => ({ ...prev, isLastPageReached: false }));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [navigationState.isLastPageReached]);

  const updateButtonStates = () => {
    const routes = routesConfig()
      .filter(route => route.showInNavbar)
      .map(route => route.path);
    
    const currentRouteIndex = routes.indexOf(location.pathname);

    setNavigationState({
      currentIndex: currentRouteIndex,
      totalRoutes: routes.length,
      isPrevDisabled: currentRouteIndex <= 0,
      isNextDisabled: currentRouteIndex >= routes.length - 1,
      isLastPageReached: currentRouteIndex === routes.length - 1
    });
  };

  const handleNavigation = (direction) => {
    const routes = routesConfig()
      .filter(route => route.showInNavbar)
      .map(route => route.path);
    
    const currentRouteIndex = routes.indexOf(location.pathname);
    const newIndex = currentRouteIndex + direction;

    if (newIndex >= 0 && newIndex < routes.length) {
      navigate(routes[newIndex]);
    } else if (newIndex === routes.length) {
      setNavigationState(prev => ({ ...prev, isLastPageReached: true }));
    }
  };

  const { currentIndex, totalRoutes, isPrevDisabled, isNextDisabled, isLastPageReached } = navigationState;

  const prevIcon = currentIndex === 0 ? "smile" : currentIndex === 1 ? "home" : "skip-previous";
  const nextIcon = currentIndex === totalRoutes - 1 ? "check" : "skip-next";
  

  return (
    <div className="navigation-control__container">
      <Button
        onlyIcon
        onClick={() => handleNavigation(-1)}
        disabled={isPrevDisabled}
        className="navigation-control__button"
      >
        <Icon name={prevIcon} />
      </Button>
      
      <span className="navigation-control__counter">
        {`${currentIndex + 1}/${totalRoutes}`}
      </span>
      
      <Button
        onlyIcon
        onClick={() => handleNavigation(1)}
        disabled={isNextDisabled}
        className={`navigation-control__button ${isLastPageReached ? 'navigation-control__button--flash' : ''}`}
      >
        <Icon name={nextIcon} />
      </Button>
    </div>
  );
};

export default NavigationControl;
