import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from '../context/themeContext';

const ViewportInfo = (props) => {
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(window.visualViewport ? window.visualViewport.height : window.innerHeight);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [bottomBarHeight, setBottomBarHeight] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      const innerHeight = window.innerHeight;
      const topBarHeight = innerHeight - viewportHeight;
      const screenHeight = window.screen.height;

      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const bottomBarHeight = screenHeight - innerHeight;

      setTopBarHeight(topBarHeight);
      setViewportHeight(viewportHeight);
      setInnerHeight(innerHeight);
      setBottomBarHeight(bottomBarHeight);

      setBodyHeight(isMobile ? viewportHeight - bottomBarHeight - topBarHeight : viewportHeight - topBarHeight);
    };

    handleResize(); // Obter valores iniciais
    window.visualViewport && window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport && window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);


  const intervalRef = useRef(null);
  

  useEffect(() => {
    const animatedBackground = document.querySelector('.animated-background');
    let angle = 0;
    let radius = 0;
    let leafCount = 0;
    const baseStrokeWidth = 8; // Valor base do stroke-width
    const centerX = window.innerWidth / 4; // Ponto de partida no centro da tela
    const centerY = window.innerHeight / 3; // Ponto de partida no centro da tela
    const leavesPerSecond = 4; // Número de leaves por segundo
    const leavesTotal = 80;
    const hueRange = 16; // Range de hue (0 a 240)
    const initialLightness = 16; // Luminosidade inicial (40 a 60)
    const lightnessRange = 4; // Range de luminosidade

    const removeLeaves = () => {
      while (animatedBackground.firstChild) {
        animatedBackground.removeChild(animatedBackground.firstChild);
      }
    };


    const createLeaves = () => {
      removeLeaves(); // Remove as leaves existentes
      let angle = 0;
      let radius = 0;
      let leafCount = 0;

      intervalRef.current = setInterval(() => {

        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute('width', '100%'); // Tamanho do SVG em relação ao pai
        svg.setAttribute('height', '100%'); // Tamanho do SVG em relação ao pai
        svg.setAttribute('viewBox', `0 0 351 320`); // Tamanho interno do desenho
        svg.setAttribute('preserveAspectRatio', 'xMidYMid meet'); // Mantém a proporção
        svg.setAttribute('fill', 'none');
        svg.setAttribute('xmlns', "http://www.w3.org/2000/svg");

        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute('d', `M151.033 3L175.611 32.9957L196.775 7.09032L243.882 16.6344L306.691 67.7634H271.19L325.807 127.073H316.932L325.807 158.432L334 229.331L290.306 189.791L278.017 194.563L233.641 152.297L250.026 182.292V233.422H233.641L227.497 273.643L214.525 268.189L187.217 320L163.322 277.733L151.033 281.824L142.158 245.692L127.821 257.963L113.484 203.426L123.042 152.297L88.2232 199.335L22 233.422L27.4617 158.432L47.2604 133.209L34.2888 127.073L75.2516 73.8989L51.3567 67.7634L71.8381 49.357V32.9957L113.484 16.6344V7.09032L151.033 3Z`);

        // Verifica se existe a variável --hue
        const hueValue = getComputedStyle(document.documentElement).getPropertyValue('--hue');
        if (hueValue) {
          // Usa o valor da variável --hue como o hue inicial
          const initialHue = parseFloat(hueValue);
          const randomHue = initialHue + Math.random() * hueRange; // Hue aleatório entre o valor inicial e o valor inicial + range
          let randomLightness = initialLightness + (Math.random() * lightnessRange * 2 - lightnessRange); // Luminosidade aleatória entre 40 e 60

          // Verifica o tema atual
          const currentTheme = theme; // Obtém o tema atual do componente pai

          if (currentTheme === 'light') {
            randomLightness = 90; // Ajusta a luminosidade para 90% se o tema for light
          }

          // Verifica se existe a variável --theme
          const themeValue = getComputedStyle(document.documentElement).getPropertyValue('--theme');
          if (themeValue === 'light') {
            randomLightness = 90; // Ajusta a luminosidade para 90% se o tema for light
          }

          path.setAttribute('fill', `hsl(${randomHue}, 100%, ${randomLightness}%)`);
        } else {
          // Se a variável não existir, usa um valor aleatório
          const randomHue = Math.random() * 240; // Hue aleatório entre 0 e 240
          let randomLightness = initialLightness + (Math.random() * lightnessRange * 2 - lightnessRange); // Luminosidade aleatória entre 40 e 60

          // Verifica o tema atual
          const currentTheme = theme; // Obtém o tema atual do componente pai
          if (currentTheme === 'light') {
            randomLightness = 90; // Ajusta a luminosidade para 90% se o tema for light
          }

          // Verifica se existe a variável --theme
          const themeValue = getComputedStyle(document.documentElement).getPropertyValue('--theme');
          if (themeValue === 'light') {
            randomLightness = 90; // Ajusta a luminosidade para 90% se o tema for light
          }

          path.setAttribute('fill', `hsl(${randomHue}, 100%, ${randomLightness}%)`);
        }

        svg.appendChild(path);

        const wrapper = document.createElement('div');
        wrapper.style.position = 'absolute';
        wrapper.style.transition = 'transform 0.3s ease';

        const x = centerX + Math.cos(angle) * radius;
        const y = centerY + Math.sin(angle) * radius;
        // Gira a leaf aleatoriamente
        const randomRotation = Math.random() * Math.PI * 2; // Gira entre 0 e 360 graus
        wrapper.style.width = `calc(80px + ${10 * leafCount}px`; // Tamanho do wrapper
        wrapper.style.transform = `translate(${x}px, ${y}px) rotate(${randomRotation}rad)`;

        if (animatedBackground.classList.contains('ease')) {
          wrapper.style.opacity = 0;
          setTimeout(() => {
            wrapper.style.opacity = 1;
            wrapper.style.transition = 'opacity 0.8s ease';
          }, 0); // Ajuste para garantir que a transição seja aplicada após o estilo ser definido
        }

        if (animatedBackground.classList.contains('flow')) {
          const startTime = Date.now();
          // wrapper.style.animation = `flowLightness ${leafCount * 140}ms linear infinite`;
          wrapper.style.animation = `flowLightness${theme == 'light' ? 'ThemeLight' : ''} 4000ms linear infinite`;
          wrapper.style.animationDelay = `${Math.random() * leafCount * 140}ms`;
        }

        wrapper.appendChild(svg);

        animatedBackground.appendChild(wrapper);

        angle += Math.PI / 6; // Ajuste o ângulo para controlar a densidade do espiral
        radius += 22; // Ajuste o raio para expandir com a escala da leaf
        leafCount++;

        if (leafCount > leavesTotal) {
          clearInterval(intervalRef.current);
        }
      }, 80)};

      createLeaves(); // Cria as leaves iniciais
  

        const handleMouseMove = (event) => {
          const leaves = document.querySelectorAll('.animated-background > div');
          const mouseX = event.clientX;
          const mouseY = event.clientY;
        
          leaves.forEach((leaf) => {
            const rect = leaf.getBoundingClientRect();
            const leafCenterX = rect.left + rect.width / 2;
            const leafCenterY = rect.top + rect.height / 2;
        
            const distance = Math.sqrt(
              Math.pow(mouseX - leafCenterX, 2) + Math.pow(mouseY - leafCenterY, 2)
            );
        
            if (distance <= 240) {
              leaf.style.transform = `${leaf.style.transform.split(' ')[0]} scale(1.2)`;
            } else {
              leaf.style.transform = leaf.style.transform.split(' ')[0];
            }
          });
        };
        
        document.addEventListener('mousemove', handleMouseMove);
        
        return () => {
          clearInterval(intervalRef.current);
          document.removeEventListener('mousemove', handleMouseMove);
          removeLeaves();
        };
      
    }, [theme]); // Refaz as leaves quando o tema muda


    useEffect(() => {
      const animatedBackground = document.querySelector('.animated-background');
      
      const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        const { innerWidth, innerHeight } = window;
        
        // Calcula o movimento como 10% da distância do centro
        const moveX = (clientX - innerWidth / 2) * 0.0005;
        const moveY = (clientY - innerHeight / 2) * 0.003;
        
        // Aplica o movimento invertido
        animatedBackground.style.transform = `translate(${-moveX}px, ${-moveY}px)`;
      };
    
      document.addEventListener('mousemove', handleMouseMove);
    
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);
    


  useEffect(() => {
    const animatedBackground = document.querySelector('.animated-background');
    document.addEventListener('click', () => {
      const wrappers = animatedBackground.children;
      let index = 0;

      function highlightNextLeaf() {
        if (index >= wrappers.length) return;

        const wrapper = wrappers[index];
        const svg = wrapper.querySelector('svg');
        const path = svg.querySelector('path');
        const originalFill = path.getAttribute('fill');
        const hslValues = originalFill.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
        if (hslValues) {
          const hue = hslValues[1];
          const newFill = `hsl(${hue}, 50%, 50%)`; // Altera saturação e luz, mantendo o hue
          path.setAttribute('fill', newFill);
        }

        setTimeout(() => {
          path.setAttribute('fill', originalFill); // Restaura a cor original
          index++;
          highlightNextLeaf();
        }, 100); // Espera antes de restaurar a cor
      }

      highlightNextLeaf();
    });
  }, []);

  // Se a prop bottomBarHeight for passada, retorna o valor numérico
  if (props.bottomBarHeight) {
    return bottomBarHeight;
  }
  

  return (
    <div className={`viewport-style ${theme}`}>
      <div className={`animated-background flow ease ${Math.random().toString(36).substr(2, 9)} ${props.className || ''}`}></div>
      {props.children}
    </div>
  );
};

export default ViewportInfo;
