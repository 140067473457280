import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './context/languageContext'; // Importando o LanguageProvider
import ViewportInfo from './utils/viewportInfo';
import { ThemeProvider } from './context/themeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <ViewportInfo>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </ViewportInfo>
    </ThemeProvider>
  </BrowserRouter>
);

reportWebVitals();
