import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../context/languageContext'; // Importando o contexto de idioma
import content from '../../data/contact.json'; // Importa o conteúdo do JSON
import Icon from '../../components/icon/icon'; // Importa o componente de ícone
import Button from '../../components/button/button';
import Tanuki from '../../assets/svgReact/svgTanuki';
import SVGController from '../../components/SVGController/SVGController';

import './contact.scss';

const Contact = () => {
  const { language } = useLanguage(); // Usando o contexto para obter o idioma
  const [data, setData] = useState({});

  useEffect(() => {
    // Filtra o conteúdo com base no idioma
    setData(content[language] || {});
  }, [language]);

  useEffect(() => {
    // Remove a classe 'visible' antes de adicionar novamente
    document.querySelectorAll('.illustration').forEach(el => {
      el.classList.remove('visible');
    });
  
    // Espera um pequeno tempo antes de adicionar a classe de volta
    const timeout = setTimeout(() => {
      document.querySelectorAll('.illustration').forEach(el => {
        el.classList.add('visible');
      });
    }, 50); // Tempo ajustável para garantir que a remoção ocorra antes
  
    return () => clearTimeout(timeout);
  }, []);
  

  // Verifica se data está carregado
  if (!data || Object.keys(data).length === 0) {
    return <div className="loading">Loading...</div>; // Mensagem de carregamento
  }

  

  return (
    <>
    <div className='illustration'>
        <SVGController>
          <Tanuki className='red-panda' alt='red-panda' width={"100%"} />
        </SVGController>
      </div>
      <div className='content'>
      <h2>{data.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
        <a href={data.button_url} target="_blank" rel="noopener noreferrer">
          {data.button_label}
        </a>
      </div>
    </>
  );
};

export default Contact;
