import React, { useState, useEffect, useRef } from 'react';
import Icon from '../icon/icon';
import './selectBox.scss';

const SelectBox = ({ 
  options, 
  selected, 
  onChange = () => {}, 
  widthEM, 
  multiSelect = false,
  placeholder = "Selecione" 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  
  // Converte selected para array se for multiSelect
  const selectedArray = multiSelect 
    ? (Array.isArray(selected) ? selected : []) 
    : (Array.isArray(selected) ? selected[0] : selected);

  // Fecha o dropdown quando clicado fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (multiSelect) {
      const newSelected = selectedArray.includes(option)
        ? selectedArray.filter(item => item !== option)
        : [...selectedArray, option];
      onChange(newSelected);
    } else {
      onChange(option);
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Calcula a largura máxima baseada no texto mais longo
  const maxLength = Math.max(...options.map(option => option.length));
  // Se for multiSelect, considere também o comprimento do texto combinado
  const multiSelectWidth = multiSelect && selectedArray.length > 0 
    ? Math.min(selectedArray.join(', ').length, 20) // limita a 20 caracteres para não ficar muito largo
    : 0;
  
  const calculatedWidth = widthEM ? widthEM : Math.max(maxLength + 0.8, multiSelectWidth + 0.8);

  // Renderiza o texto selecionado
  const renderSelectedValue = () => {
    if (multiSelect) {
      if (selectedArray.length === 0) {
        return <span className="select-box__placeholder">{placeholder}</span>;
      }
      if (selectedArray.length === 1) {
        return selectedArray[0];
      }
      return `${selectedArray.length} selecionados`;
    } else {
      return selectedArray || <span className="select-box__placeholder">{placeholder}</span>;
    }
  };

  return (
    <div 
      ref={selectRef}
      className={`select-box ${isOpen ? 'select-box--open' : ''} ${multiSelect ? 'select-box--multi' : ''}`}
      style={{ width: `${calculatedWidth}em` }}
    >
      <div
        className="select-box__current"
        tabIndex="0"
        onClick={toggleDropdown}
      >
        <div className="select-box__value">
          {renderSelectedValue()}
        </div>
        <Icon name="chevron-down" />
      </div>
      {isOpen && (
        <ul className="select-box__list">
          {options.map((option, index) => (
            <li key={index}>
              <label
                className={`select-box__option ${
                  multiSelect && selectedArray.includes(option) ? 'select-box__option--selected' : ''
                } ${
                  !multiSelect && option === selectedArray ? 'select-box__option--selected' : ''
                }`}
                onClick={() => handleOptionClick(option)}
                aria-hidden="true"
              >
                {multiSelect && (
                  <span className="select-box__checkbox">
                    {selectedArray.includes(option) && <Icon name="check" />}
                  </span>
                )}
                {option}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectBox;
