import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './nav.scss';
import Icon from '../icon/icon';
import Button from '../button/button';
import { applyTheme } from './../../utils/themeControler';
import { setUrlParams } from '../../utils/urlParams';
import routesConfig from '../../routesConfig';
import { useLanguage } from '../../context/languageContext';

const Nav = ({ theme, setTheme, pageKey }) => {
  const { language, setLanguage } = useLanguage();
  const [navOpened, setNavOpened] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const navRef = useRef(null);

  const currentRoute = routesConfig(language).find(route => route.path === location.pathname);
  const pageTitle = currentRoute ? currentRoute.title : '';

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    applyTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    setUrlParams({ theme: newTheme });
  };

  const toggleLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    setUrlParams({ language: lang });
    navigate(`${location.pathname}?${new URLSearchParams({ language: lang }).toString()}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setNavOpened(false);
    document.title = pageTitle + ' - garaujo.space';
  }, [location.pathname, pageTitle]);

  return (
    <nav ref={navRef} className={`nav ${navOpened ? 'nav--opened' : ''} ${pageKey}`}>
      <div className="nav__trigger">
        <div className="nav__name">
          <NavLink to={'/'}>garaujo</NavLink>.space
        </div>
        {pageTitle && <div className="nav__page-title">{pageTitle}</div>}
        <div className="nav__button btn only-icon" onClick={() => setNavOpened(!navOpened)}>
          {navOpened ? <Icon name="menu-collapse" /> : <Icon name="menu-expand" />}
        </div>
      </div>
      <div className={`nav__options ${navOpened ? 'nav__options--opened' : ''}`}>
        <ul className="nav__list">
          {routesConfig(language)
            .filter(route => route.showInNavbar)
            .map((route, i) => (
              <li className="nav__option" key={i}>
                <NavLink to={route.path}>
                  {route.title}
                </NavLink>
              </li>
            ))}
          <li className="nav__option button-list">
            <Button onlyIcon onClick={toggleTheme}>
              <Icon name={theme === 'light' ? 'moon' : 'sun'} />
            </Button>
            <Button onlyIcon onClick={() => toggleLanguage('en')}>EN</Button>
            <Button onlyIcon onClick={() => toggleLanguage('pt')}>PT</Button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
