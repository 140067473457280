// /src/pages/home/Home.js
import React, { useEffect, useState } from 'react';
// import { ReactSVG } from 'react-svg';
import SVGController from '../../components/SVGController/SVGController';
import './home.scss';
import Panda from '../../assets/svgReact/svgPanda';

const HomeContent = () => {
  
  
  useEffect(() => {
    
    const timeout = setTimeout(() => {
      document.querySelector(".illustration")?.classList.add("visible");
    }, 50);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className='illustration'>
        <SVGController>
          <Panda className='red-panda' alt='red-panda' width={"100%"} />
        </SVGController>
      </div>
      <section className='hero'>
        <span>De<b>sign</b></span>
        <span>De<b>ve</b><b>lop</b></span>
        <span>De<b>l</b><b>i</b>ve<b>r</b></span>
      </section>
    </>

  );
};

const Home = () => {
  return <HomeContent />
};

export default Home;
