import React, { useEffect, useState } from 'react';
import Icon from '../../components/icon/icon';
import content from '../../data/about-me.json'; // Caminho para o arquivo JSON
import { useLanguage } from '../../context/languageContext'; // Importando o contexto
import { getUrlParams } from '../../utils/urlParams'; // Importa a função para ler parâmetros da URL
import Koi from '../../assets/svgReact/svgKoi';
import SVGController from '../../components/SVGController/SVGController';
import './about.scss';

const About = () => {
  const { language, setLanguage } = useLanguage(); // Usando o contexto
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(content[language] || []);
  }, [language]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelectorAll('.illustration').forEach(el => {
        el.classList.remove('visible');
      });

      // Adicionar a classe 'visible' aos elementos 'illustration' da nova página
      document.querySelectorAll('.illustration').forEach(el => {
        el.classList.add('visible');
      });
    }, 50);

    return () => clearTimeout(timeout);
  }, []);

  if (!data || !Array.isArray(data)) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
      <div className='illustration'>
        <SVGController>
          <Koi className='red-panda' alt='red-panda' width={"100%"} />
        </SVGController>
      </div>
      <div className='content'>
      {data.map((item, index) => (
        <div key={index}>
          <h2>{item.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
          <div className='list-icons'>
            {item.links?.map((link, index) => (
              <div key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  <Icon size={'56px'} name={link.icon} />
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
      
    </>
  );
};

export default About;
