export const applyTheme = (theme) => {
    const rootElement = document.documentElement;
    if (theme === 'light') {
        // Mudar para tema claro
        rootElement.style.setProperty('--theme', 'light');
        rootElement.style.setProperty('--color-bg', 'hsla(var(--hue), 50%, 95%, 1)');
        rootElement.style.setProperty('--color-bg-2', 'hsla(var(--hue), 50%, 90%, 1)');
        rootElement.style.setProperty('--color-bg-3', 'hsla(var(--hue), 50%, 85%, 1)');
        rootElement.style.setProperty('--color-text', 'hsla(var(--hue), 0%, 10%, 1)');
        rootElement.style.setProperty('--color-text-secondary', 'hsla(var(--hue), 0%, 20%, 0.666)');
    } else {
        // Mudar para tema escuro
        rootElement.style.setProperty('--theme', 'dark');
        rootElement.style.setProperty('--color-bg', 'hsla(var(--hue), 100%, 14%, 1)');
        rootElement.style.setProperty('--color-bg-2', 'hsla(var(--hue), 100%, 22%, 1)');
        rootElement.style.setProperty('--color-bg-3', 'hsla(var(--hue), 100%, 28%, 1)');
        rootElement.style.setProperty('--color-text', 'hsla(var(--hue), 100%, 100%, 1)');
        rootElement.style.setProperty('--color-text-secondary', 'hsla(var(--hue), 100%, 100%, 0.666)');
    }
    localStorage.setItem('theme', theme); // Grava no local storage
};
