import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import Container from './components/container/container';
import routesConfig from './routesConfig';
import { applyTheme } from './utils/themeControler';
import { useLanguage } from './context/languageContext';
import { BannerProvider } from './context/bannerContext';
import ScrollToTop from './utils/scroll-to';
import './App.scss';
import NavigationControl from './components/navigationControl/navigationControl';
import { ThemeContext } from './context/themeContext';

function App() {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { language, changeLanguage } = useLanguage();
  const [contentTypes, setContentTypes] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-984ZG8DPGS";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-984ZG8DPGS");
  }, []);

  useEffect(() => {
    // Lê os parâmetros da URL ao carregar o componente
    const urlLanguage = searchParams.get('language');
    const urlTheme = searchParams.get('theme');
    const urlContent = searchParams.get('content');
    

    // Aplica o tema da URL ou usa o padrão
    if (urlTheme && ['light', 'dark'].includes(urlTheme)) {
      setTheme(urlTheme);
      applyTheme(urlTheme);
      localStorage.setItem('theme', urlTheme);
    } else {
      const savedTheme = localStorage.getItem('theme');
      const initialTheme = savedTheme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
      setTheme(initialTheme);
      applyTheme(initialTheme);
    }

    // Aplica a linguagem da URL
    if (urlLanguage && ['pt', 'en'].includes(urlLanguage)) {
      changeLanguage(urlLanguage);
    }

    // Configura os tipos de conteúdo a serem exibidos
    if (urlContent) {
      const contentArray = urlContent.split(',');
      setContentTypes(contentArray);
    } else {
      setContentTypes([]); // Sem filtro, mostra tudo
    }
  }, [searchParams, changeLanguage]);

  // Função para atualizar os parâmetros da URL
  const updateUrlParams = (params) => {
    const newParams = new URLSearchParams(searchParams);
    
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
    });
    
    setSearchParams(newParams);
  };

  // Função para mudar o tema com atualização da URL
  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    applyTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    updateUrlParams({ theme: newTheme });
  };

  // Função para mudar a linguagem com atualização da URL
  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
    updateUrlParams({ language: newLanguage });
  };

  // Função para filtrar conteúdo por tipo
  const filterContentByType = (types) => {
    setContentTypes(types);
    updateUrlParams({ content: types.join(',') });
  };

  // Obtém a chave da página com base na rota atual
  const getPageKey = () => {
    const route = routesConfig(language).find(route => route.path === location.pathname);
    if (!route) return '';

    // Encontrar a chave correspondente ao título
    const currentTranslations = Object.entries(routesConfig.translations[language]);
    const key = currentTranslations.find(([key, value]) => value === route.title)?.[0];
    return key || '';
  };

  return (
    <>
      <BannerProvider>
        <Nav 
          pageKey={getPageKey()} 
          theme={theme} 
          setTheme={handleThemeChange} 
          language={language} 
          setLanguage={handleLanguageChange} 
        />
        <Container 
          pageKey={getPageKey()} 
          footer={<Footer />}
          contentTypes={contentTypes}
          filterContentByType={filterContentByType}
        >
          <Routes>
            {routesConfig(language).map((route, index) => (
              <Route 
                key={index} 
                path={route.path} 
                element={
                  <route.component 
                    contentTypes={contentTypes} 
                    filterContentByType={filterContentByType}
                    theme={theme} // Passa o tema como prop
                  />
                } 
              />
            ))}
          </Routes>
        </Container>
        <NavigationControl />
      </BannerProvider>
    </>
  );
}

export default App;
