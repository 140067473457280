import React from 'react';
import './tag.scss';

const Tag = ({ 
  children, 
  onClick, 
  active, 
  clickable, 
  disabled, 
  highlight = false, 
  toggleable = false,
  selected = false,
  className = '',
  ...props
}) => {
    let classNames = ['tag'];
    
    if (clickable) classNames.push('tag--clickable');
    if (active) classNames.push('tag--active');
    if (disabled) classNames.push('tag--disabled');
    if (highlight) classNames.push('tag--highlight');
    if (toggleable) classNames.push('tag--toggleable');
    if (selected) classNames.push('tag--selected');
    if (className) classNames.push(className);

    const handleClick = (e) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    };

    return (
        <span 
          className={classNames.join(' ')} 
          onClick={handleClick} 
          {...props}
        >
            {children}
        </span>
    );
};

export default Tag;
